<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <q-form ref="editForm">
        <c-card title="공사현장 기본정보" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline>
              <c-btn
                v-if="editable"
                :url="saveInfoUrl"
                :isSubmit="isSave"
                :param="data"
                :mappingType="mappingType"
                label="저장"
                icon="save"
                @beforeAction="saveInfo"
                @btnCallback="saveCallback"
              />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
              <c-text
                :required="true"
                :editable="editable"
                label="공사현장 명"
                name="plantName"
                v-model="data.plantName"
              >
              </c-text>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
              <c-datepicker
                :required="true"
                :editable="editable"
                :range="true"
                label="공사 기간"
                name="projectTerm"
                v-model="data.projectTerm"
              />
            </div>
            <div class="col-2">
              <c-text
                type="number"
                :required="true"
                :editable="editable"
                label="공사금액"
                name="projectCost"
                suffix="원"
                v-model="data.projectCost"
              >
              </c-text>
            </div>
            <div class="col-2">
              <c-text
                type="number"
                :required="true"
                :editable="editable"
                label="안전관리비"
                name="projectSafetyCost"
                suffix="원"
                v-model="data.projectSafetyCost"
              >
              </c-text>
            </div>
            <div class="col-2">
              <c-checkbox
                v-if="param.plantCd"
                :editable="editable"
                :isFlag="true"
                label="공사완료여부"
                name="finishFlag"
                v-model="data.finishFlag"
                @input="changeFinish"
              />
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        title="안전보건체제"
        tableId="grid"
        :columns="grid.columns"
        :data="data.projectOrgList"
        selection="multiple"
        rowKey="pjmProjectOrgId"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        :editable="editable"
        :usePaging="false"
        gridHeight="300px"
        :isInUserAll="true"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addrow" />
            <c-btn v-if="editable" label="제외" icon="remove" @btnClicked="deleteOrg" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid2"
        title="관계사"
        tableId="grid2"
        :columns="grid2.columns"
        :data="data.projectRelateList"
        selection="multiple"
        rowKey="pjmProjectStackholderId"
        :isFullScreen="false"
        :editable="editable"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        gridHeight="300px"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addMat" />
            <c-btn v-if="editable" label="제외" icon="remove" @btnClicked="deleteMat" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-bom',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: '',
        updateFlag: '',
      }),
    },
  },
  data() {
    return {
      originprojectAreaFlag: '',
      originbookFlag: '',
      data: {
        companyCd: '',
        plantCd: '',  // 공사현장코드
        plantName: '',  // 공사현장 명
        projectTerm: [],
        projectStartDt: '',  // 공사시작일
        projectEndDt: '',  // 공사종료일
        projectAddress: '',  // 공사현장 소재지
        projectMngNo: '',  // 공사관리번호
        projectCost: '',  // 공사금액
        projectSafetyCost: '',  // 안전관리비
        remarks: '',  // 공사개요
        projectMngUserId: '',  // 현장소장사용자ID
        projectAreaFlag: 'N', // 공사구역관리여부
        bookFlag: 'N', // 안전보건대장 대상여부 
        finishFlag: 'N',  // 공사완료여부
        afterCnt: '',
        projectOrgList: [],
        deleteProjectOrgList: [],
        projectRelateList: [],
        deleteProjectRelateList: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [],
        data: [],
      },
      grid2: {
        columns: [],
        data: [],
      },
      editable: true,
      checkUrl: '',
      infoUrl: '',
      saveInfoUrl: transactionConfig.pjm.info.insert.url,
      mappingType: 'POST',
      isSave: false,
      insertInfoUrl: '',
      updateInfoUrl: '',
      deleteInfoUrl: '',
      listOrgUrl: '',
      saveOrgUrl: '',
      deleteOrgUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    param() {
      this.getInfo();
    }
  },
  methods: {
    init() {
      // role setting
      if(this.$store.getters.user.innerFlag === 'N') {
        this.editable = false;
      } else {
        this.editable = this.$route.meta.editable;
      }
      // url setting
      this.infoUrl = selectConfig.pjm.info.get.url;
      this.checkUrl = selectConfig.ten.company.check.plant.url;
      this.insertInfoUrl = transactionConfig.pjm.info.insert.url;
      this.updateInfoUrl = transactionConfig.pjm.info.update.url;
      this.deleteInfoUrl = transactionConfig.pjm.info.delete.url;
      this.listOrgUrl = selectConfig.pjm.org.list.url;
      this.saveOrgUrl = transactionConfig.pjm.org.save.url;
      this.deleteOrgUrl = transactionConfig.pjm.org.delete.url;
      
      this.$comm.getComboItems('SAFETY_ORG_ROLE').then(_result => {
        _result = this.$_.filter(_result, {attrVal1 : 'Y' });
        this.grid.columns = [
          {
            name: 'projectRole',
            field: 'projectRole',
            valueName: 'codeName',
            label: '역할',
            align: 'center',
            style: "width:200px",
            sortable: false,
            type: 'select',
            required: true,
            comboItems: _result,
          },
          {
            name: 'projectRoleUserName',
            field: 'projectRoleUserName',
            label: '담당자',
            align: 'left',
            sortable: false,
            required: true,
            style: 'width:150px',
            type: 'user',
            userId: 'projectRoleUserId',
          },
          {
            name: 'roleStartDt',
            field: 'roleStartDt',
            label: '투입일',
            align: 'center',
            type: 'date',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'roleEndDt',
            field: 'roleEndDt',
            label: '철수일',
            align: 'center',
            type: 'date',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'roleAppointDt',
            field: 'roleAppointDt',
            label: '선임일',
            align: 'center',
            type: 'date',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'col7',
            field: 'col7',
            label: '첨부파일',
            align: 'center',
            style: 'width:300px',
            type: 'attach',
            taskClassCd: 'SAFETY_ORG_ROLE_ATTR1',
            keyText: 'pjmProjectOrgId',
            sortable: false,
          },
        ]
      });
      this.$comm.getComboItems('PROJECT_STACKHOLDER_CD').then(_result => {
        this.grid2.columns = [
          {
            name: 'projectStackholderCd',
            field: 'projectStackholderCd',
            label: '구분',
            required: true,
            valueName: 'codeName',
            align: 'center',
            style: "width:160px",
            sortable: false,
            type: 'select',
            comboItems: _result,
          },
          {
            name: 'companyName',
            field: 'companyName',
            label: '회사명',
            required: true,
            style: "width:350px",
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'ceoName',
            field: 'ceoName',
            label: '대표자',
            align: 'center',
            style: "width:120px",
            type: 'text',
            sortable: false,
          },
          {
            name: 'companyAddress',
            field: 'companyAddress',
            label: '주소',
            align: 'left',
            type: 'text',
            sortable: true,
          },
          {
            name: 'companyPhone',
            field: 'companyPhone',
            label: '전화번호',
            align: 'center',
            style: "width:130px",
            mask: "###-####-####",
            type: 'text',
            sortable: false,
          },
          {
            name: 'companyEmail',
            field: 'companyEmail',
            label: '이메일주소',
            align: 'center',
            style: "width:180px",
            type: 'text',
            sortable: false,
          },
          {
            name: 'managerName',
            field: 'managerName',
            label: '담당자',
            align: 'center',
            style: "width:120px",
            type: 'text',
            sortable: false,
          },
          {
            name: 'managerPhone',
            field: 'managerPhone',
            label: '담당자 연락처',
            align: 'center',
            style: "width:130px",
            mask: "###-####-####",
            type: 'text',
            sortable: false,
          },
        ]
      });
      this.getInfo();
    },
    addMat() {
      this.data.projectRelateList.push({
        editFlag: 'C',
        plantCd: this.param.plantCd,  // 공사현장코드
        pjmProjectStackholderId: uid(),  // 이해관계자 일련번호
        projectStackholderCd: null,  // 이해관계자 구분
        companyName: '',  // 회사명
        ceoName: '',  // 대표자
        companyAddress: '',  // 주소
        companyPhone: '',  // 전화번호
        companyEmail: '',  // 이메일주소
        managerName: '',  // 담당자
        managerPhone: '',  // 담당자연락처
      })
    },
    deleteMat() {
      let selectData = this.$refs['grid2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.data.deleteProjectRelateList) this.data.deleteProjectRelateList = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.data.deleteProjectRelateList, { pjmProjectStackholderId: item.pjmProjectStackholderId }) === -1) {
            this.data.deleteProjectRelateList.push(item)
          }
          this.data.projectRelateList = this.$_.reject(this.data.projectRelateList, item);
        });
      }
    },
    changeFinish(_data) {
      if(_data == 'Y') {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '공사완료로 저장 시, 도급업체 포탈에서 공사현장 조회가 되지 않습니다.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      }
    },
    getInfo() {
      if (this.param.plantCd) {
        this.$http.url = this.$format(this.infoUrl, this.param.plantCd);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.originprojectAreaFlag = _result.data.projectAreaFlag;
          this.originbookFlag = _result.data.bookFlag;
        },);
      } else {
        this.originprojectAreaFlag = this.data.projectAreaFlag;
        this.originbookFlag = this.data.bookFlag;
      }
    },
    saveInfo() {
      let _msg = '';
      if (this.param.plantCd) {
        this.saveUrl = this.updateInfoUrl;
        this.mappingType = 'PUT';
        _msg = '저장하시겠습니까?';
      } else {
        this.saveUrl = this.insertInfoUrl;
        this.data.companyCd = this.$store.getters.user.companyCd;
        this.mappingType = 'POST';
        _msg = '저장하시겠습니까?';
        // _msg = '신규공사현장용 기준정보 생성으로 시간이 소요될 수 있습니다.\n\r저장하시겠습니까?';
      }
      this.$refs['editForm'].validate().then((_result) => {
        if (_result) {
          this.$http.url = this.checkUrl;
          this.$http.param = {
            companyCd: this.$store.getters.user.companyCd,
          }
          this.$http.type = 'GET';
          this.$http.request((_result2) => {
            if (this.$comm.validTable(this.grid.columns, this.data.projectOrgList)) {
              if (Number(_result2.data['nowPlantCd']) < Number(_result2.data['maxPlantCd'])) {
                window.getApp.$emit('CONFIRM', {
                  title: '확인',
                  message: _msg,
                  // TODO : 필요시 추가하세요.
                  type: 'info', // success / info / warning / error
                  // 확인 callback 함수
                  confirmCallback: () => {
                    this.data.afterCnt = Number(_result2.data['nowPlantCd']) + 1
                    this.data.regUserId = this.$store.getters.user.userId;
                    this.data.chgUserId = this.$store.getters.user.userId;
                    this.data.projectStartDt = this.data.projectTerm[0];
                    this.data.projectEndDt = this.data.projectTerm[1];
                    this.isSave = !this.isSave;
                  },
                  // 취소 callback 함수
                  cancelCallback: () => {},
                });
              } else {
                window.getApp.$emit('ALERT', {
                  title: '안내', // 안내
                  message:
                  '등록할 수 있는 공사현장은 최대 ' + _result2.data['maxPlantCd'] + '개 입니다.',
                  type: 'warning', // success / info / warning / error
                });
                return;
              }
            }
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType === 'POST') {
        this.$emit('updateMode', result.data.plantCd);
        window.sessionStorage.setItem('defaultPlantCd', result.data.plantCd);
        location.reload(true);
      }
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.param.plantCd = result.data.plantCd;
      this.getInfo();
    },
    addrow() {
      this.data.projectOrgList.push({
        editFlag: 'C',
        plantCd: this.data.plantCd,  // 공사현장코드
        pjmProjectAreaId: this.data.plantCd,  // 공사구역코드(전체현장 보건체계에서는 공사현장코드가 들어감)
        pjmProjectOrgId: uid(),  // 안전조직 일련번호
        projectRole: null,  // 역할
        projectRoleUserId: '',  // 담당자 ID
        roleStartDt: '',  // 근무시작일
        roleEndDt: '',  // 근무종료일
        roleAppointDt: '',  // 선임일
        eduCompleteDt: '',  // 교육수료일
      })
    },
    deleteOrg() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.data.deleteProjectOrgList) this.data.deleteProjectOrgList = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.data.deleteProjectOrgList, { pjmProjectOrgId: item.pjmProjectOrgId }) === -1) {
            this.data.deleteProjectOrgList.push(item)
          }
          this.data.projectOrgList = this.$_.reject(this.data.projectOrgList, item);
        });
      }
    },
  }
};
</script>